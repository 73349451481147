( function( common ) {

    'use strict';

    /**
     * @module TemplateHelpers
     *
     * A combination of utilties to use when templating, these are generally formatters
     * @type {Object}
     */
    common.template = {};

    /**
     * Stringifies & escapes strings so they can be put into HTML
     * @param  {String} string the string to clean
     * @return {String}        output
     */
    common.template.cleanString = function( string ) {
        return _.escape( JSON.stringify( string || '' ) );
    };

    /**
     * Prints a number with commas
     * @param  {Number} number Number value to print with commas as thousands
     * @return {String}        Converted value with commas
     */
    common.template.getNumberWithCommas = function( number ) {
        return number.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
    };

    /**
     * Given a single source variant config, return template-friendly strings
     * @param  {MediaQueryConfig} conf - the media query config for a single source set
     * @return {Object}                - the srcset and media query, in string format
     */
    common.template.getSourceConfig = function getSourceConfig( conf ) {
        let srcset = '';
        if ( conf.variantUrls.length > 1 ) {
            srcset = `${conf.variantUrls.join( ', ' )} 2x`;
        } else {
            srcset = conf.variantUrls.join( '' );
        }
        let mediaQuery = [];
        if ( typeof conf.minWidth !== 'undefined' ) {
            mediaQuery.push( `(min-width: ${conf.minWidth}px)` );
        }
        if ( typeof conf.maxWidth !== 'undefined' ) {
            mediaQuery.push( `(max-width: ${conf.maxWidth}px)` );
        }
        return {
            srcset,
            mediaQuery: mediaQuery.join( ' and ' )
        };
    };

}( PULSE.app.common ) );
