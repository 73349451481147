( function( app, common ) {
    'use strict';

    /* eslint-disable no-magic-numbers  */

    common.content = {};

    const DEFAULT_MULTIPLIERS = [ 1, 2 ];

    /**
     * Works out whether the content item is tagged with the given tag label
     *
     * @param {ContentResponse} contentItem - Element Item
     * @param {String} tagLabel - Tag
     *
     * @return {Boolean} hasTag?
     */
    common.content.hasTag = function( contentItem, tagLabel ) {
        let hasTag = false;
        if ( contentItem.tags.length > 0 ) {
            contentItem.tags.forEach( function( item ) {
                if ( typeof item.label !== 'undefined' && item.label === tagLabel ) {
                    hasTag = true;
                }
            } );
        }

        return hasTag;
    };

    /**
     * Returns a friendly content type based on what is provided from platform
     * @param {String} type - The type given from platform
     * @return {String} - The friendly type
     */
    common.content.getSpecificTag = function( tags, category ) {
        let tag = '';
        if ( tags.length ) {
            for ( var i = 0; i < tags.length; i++ ) {
                if ( tags[ i ].label.indexOf( category + ':' ) !== -1 ) {
                    tag = tags[ i ].label.replace( category + ':', '' );
                    break;
                }
            }
        }
        return tag;
    };

    /**
     * Pre-processing function before using a content response in an underscore template
     * @param {ContentResponse} contentItem The API content response
     * @param {MediaQuerySpecification} mediaQuerySpec Spec on the required variants for the template
     * @param {contentType} restriction Playlist restricted content type, if any
     * @return {ContentResponse} (extended)
     */
    common.content.getContentModel = function( contentItem, mediaQuerySpec ) {
        if ( contentItem.type !== 'photo' ) {
            const image = common.content.getImageFromContentItem( contentItem );
            contentItem.mediaQueryConfig = _extendMQConfigWithUrls( image, mediaQuerySpec );
            contentItem.link = common.generateUrl( contentItem.type, contentItem );
        } else {
            contentItem.mediaQueryConfig = _extendMQConfigWithUrls( contentItem, mediaQuerySpec );
            if ( contentItem.playlist ) {
                contentItem.link = common.generateUrl( contentItem.type, contentItem.playlist, 'PHOTO' );
                contentItem.link += common.generateUrl( contentItem.type, contentItem );
            } else {
                contentItem.link = '';
            }
        }

        return contentItem;
    };

    /**
     * Returns the total duration (user-friendly string) for a list of videos
     * @param  {Object[]} videoList array of video API content responses
     * @return {String}             user-friendly total (e.g., 1:05:23)
     */
    common.content.getTotalDuration = function( videoList ) {
        var total = videoList.reduce( function( sum, video ) {
            return sum + video.duration;
        }, 0 );
        return common.durationToTime( total );
    };

    /**
     * @typedef {Object} VariantSpecification
     * @property {TagLabel} tagLabel
     * @property {Number} preferredWidth
     * @property {Number} preferredHeight
     */

    /**
     * Given a map of variable names (as properties) and variant tags, it will try to retrieve the URLs for the matching variants from the given variants array
     * @param {Array<Variant>} variants - Array of variant properties
     * @param {VariantSpecification} spec (please note preferredHeight and preferredWidth are not yet supported by the image helper)
     * @return {Object} urls
     */
    common.content.getVariantUrls = function( variants, spec ) {
        var urls = {}, property;

        var setUrl = function( tag, name ) {
            var variant = common.image.getVariantByTag( variants, tag );
            if ( variant ) {
                urls[ name ] = variant.url;
            }
        };

        if ( variants.length ) {
            // FTL-type variant spec
            if ( _.isArray( spec ) ) {
                spec.forEach( function( screenSizeSpec ) {
                    screenSizeSpec.tags.forEach( function( tag, index ) {
                        var name = screenSizeSpec.name + ( index === 0 ? '' : 'Retina' );
                        setUrl( tag, name );
                    } );
                } );
            } else if ( typeof spec === 'object' ) {
                for ( property in spec ) {
                    if ( {}.hasOwnProperty.call( spec, property ) ) {
                        setUrl( spec[ property ], property );
                    }
                }
            }

        }
        return urls;
    };

    /**
     * Retrieves the array of photo variants of a content item
     * @param {ContentResponse} contentItem - content data from API
     * @return {Array<Variant>} varaints
     */
    common.content.getVariants = function( contentItem ) {
        var variants = [];
        var leadMedia = contentItem.leadMedia || contentItem.coverItem || contentItem.thumbnail;
        if ( leadMedia ) {
            if ( leadMedia.type === 'video' ) {
                if ( leadMedia.thumbnail !== null ) {
                    if ( leadMedia.thumbnail.variants !== null && leadMedia.thumbnail.variants.length > 0 ) {
                        return leadMedia.thumbnail.variants;
                    }
                }
            } else if ( leadMedia.variants !== null || leadMedia.variants.length > 0 ) {
                return leadMedia.variants;
            }
        }
        return variants;
    };

    /**
     * Retrieves the array of photo variants of a content item
     * @param {ContentResponse} contentItem - content data from API
     * @return {Array<Variant>} variants
     */
    common.content.getImageFromContentItem = function( contentItem ) {
        let leadMedia;
        if ( contentItem.type === 'photo' ) { // Base case for photos: just return the item as-is
            leadMedia = contentItem;
        } else if ( contentItem.type === 'video' && contentItem.thumbnail ) { // Videos have photos as thumbnail items
            leadMedia = contentItem.thumbnail;
        } else {
            let photoOrVideoCover = contentItem.leadMedia || contentItem.coverItem || contentItem.promoItem;

            // Special case for playlists that have playlists as cover items
            if ( contentItem.type === 'playlist' && contentItem.coverItem && contentItem.coverItem.type === 'playlist' ) {
                photoOrVideoCover = contentItem.coverItem.coverItem;
            }

            // For content items that take photos or videos as cover media
            if ( photoOrVideoCover ) {
                if ( photoOrVideoCover.type === 'photo' ) {
                    leadMedia = photoOrVideoCover;
                } else if ( photoOrVideoCover.type === 'video' && photoOrVideoCover.thumbnail ) {
                    leadMedia = photoOrVideoCover.thumbnail;
                }
            }
        }

        return leadMedia;
    };

    //////////////////////////////
    // Private
    //////

    /**
     * Creates a new mediaQueryConfig object (based on the given one), extended with variant URLs
     * @param  {PhotoResponse}      image          - the image/photo, as retrieved from the API
     * @param  {MediaQueryConfig[]} mediaQuerySpec - the array of configuration for each media source set
     * @return {Object[]}                          - adds a variantUrls property to each config, with the URLs of the variants
     */
    const _extendMQConfigWithUrls = function _extendMQConfigWithUrls( image, mediaQuerySpec ) {
        if ( image && image.onDemandUrl ) {
            return mediaQuerySpec.map( mediaQueryConfig => {
                let conf = { ...mediaQueryConfig }; // make a copy of the config
                const multipliers = conf.multipliers || DEFAULT_MULTIPLIERS;
                conf.variantUrls = multipliers.map( multiplier => {
                    const height = ( conf.size.height || 0 ) * multiplier;
                    const width = ( conf.size.width || 0 ) * multiplier;
                    return common.image.getOnDemandImageUrl( image, height, width );
                } );
                return conf;
            } );
        }
        
        // @TODO
        
    };

}( PULSE.app, PULSE.app.common ) );
