( function( common ) {

    /**
     * List of elements to close if the user clicks outside of their defined boundary
     * @type {Array<DOMElement>}
     */
    const elementsToClose = [];

    /**
     * Function to be executed when the click listener on the document is fired
     * @param {DOMEvent} eventObj - The click event
     */
    const onDocumentClick = function( eventObj ) {

        let elem;
        for ( var i = 0; i < elementsToClose.length; i++ ) {

            elem = elementsToClose[ i ];

            if ( elem && eventObj.target !== elem.preventTriggerOn &&
                ( !elem.preventTriggerOn || !elem.preventTriggerOn.contains( eventObj.target ) ) &&
                elem.element !== eventObj.target &&
                elem.preventTriggerOn !== eventObj.target &&
                typeof elem.callback === 'function' ) {

                elem.callback( elem.element );
            }
        }
    };

    /*
     * Functionality which closes all open elements on a document click/click outside of the element
     * element specifically needs to be added to the elementsToClose list
     * @class CloseOnOutsideClick
     * @static
     */
    common.closeOnOutsideClick = {

        /**
         * Adds an element to the elementsToClose list
         * @memberof CloseOnOutsideClick
         *
         * @param {DOMElement} element            - The element
         * @param {Function}   callback           - The callback (gets run on close)
         * @param {DOMElement} preventTriggerOn - Optional element to prevent close on
         */
        addElement: function( element, callback, preventTriggerOn ) {

            if ( elementsToClose.length === 0 ) {
                document.addEventListener( 'click', onDocumentClick );
            }

            elementsToClose.push( {
                element,
                callback,
                preventTriggerOn
            } );
        },

        /**
         * Removes an element from the elementsToClose list
         * @memberof CloseOnOutsideClick
         *
         * @param {DOMElement} element - The element to remove
         */
        removeElement: function( element ) {

            for ( let i = 0; i < elementsToClose.length; i++ ) {
                if ( elementsToClose[ i ] &&
                    elementsToClose[ i ].element === element ) {

                    elementsToClose.splice( i, 1 );
                }
            }

            if ( elementsToClose.length === 0 ) {
                document.removeEventListener( 'click', onDocumentClick );
            }

        }
    };

}( PULSE.app.common ) );
