/* eslint-disable consistent-return, max-lines, no-magic-numbers */

( function( common ) {

    var CURRENCIES = {
        AED: {
            name: 'UAE Dirham',
            format: '%amount% .د.إ'
        },
        AFN: {
            name: 'Afghani',
            format: '%amount% ؋'
        },
        ALL: {
            name: 'Lek',
            format: 'L%amount%'
        },
        AMD: {
            name: 'Armenian Dram',
            format: '%amount% դր.'
        },
        ANG: {
            name: 'Netherlands Antillean Guilder',
            format: 'ƒ%amount%'
        },
        AOA: {
            name: 'Kwanza',
            format: '%amount%'
        },
        ARS: {
            name: 'Argentine Peso',
            format: '$%amount%'
        },
        AUD: {
            name: 'Australian Dollar',
            format: '$%amount%'
        },
        AWG: {
            name: 'Aruban Florin',
            format: 'ƒ%amount%'
        },
        AZN: {
            name: 'Azerbaijanian Manat',
            format: '₼%amount%'
        },
        BAM: {
            name: 'Convertible Mark',
            format: 'KM%amount%'
        },
        BBD: {
            name: 'Barbados Dollar',
            format: '$%amount%'
        },
        BDT: {
            name: 'Taka',
            format: '৳%amount%'
        },
        BGN: {
            name: 'Bulgarian Lev',
            format: 'лв%amount%'
        },
        BHD: {
            name: 'Bahraini Dinar',
            format: '%amount% .د.ب'
        },
        BIF: {
            name: 'Burundi Franc',
            format: 'FBu %amount%'
        },
        BMD: {
            name: 'Bermudian Dollar',
            format: '$%amount%'
        },
        BND: {
            name: 'Brunei Dollar',
            format: '$%amount%'
        },
        BOB: {
            name: 'Boliviano',
            format: 'Bs.%amount%'
        },
        BOV: {
            name: 'Mvdol',
            format: 'BOV %amount%'
        },
        BRL: {
            name: 'Brazilian Real',
            format: 'R$%amount%'
        },
        BSD: {
            name: 'Bahamian Dollar',
            format: '$%amount%'
        },
        BTN: {
            name: 'Ngultrum',
            format: 'Nu %amount%'
        },
        BWP: {
            name: 'Pula',
            format: 'P%amount%'
        },
        BYN: {
            name: 'Belarussian Ruble',
            format: '%amount% p.'
        },
        BYR: {
            name: 'Belarussian Ruble',
            format: '%amount% p.'
        },
        BZD: {
            name: 'Belize Dollar',
            format: 'BZ$%amount%'
        },
        CAD: {
            name: 'Canadian Dollar',
            format: '$%amount%'
        },
        CDF: {
            name: 'Congolese Franc',
            format: 'FC %amount%'
        },
        CHE: {
            name: 'WIR Euro',
            format: '%amount%'
        },
        CHF: {
            name: 'Swiss Franc',
            format: 'Fr. %amount%'
        },
        CHW: {
            name: 'WIR Franc',
            format: '%amount%'
        },
        CLF: {
            name: 'Unidad de Fomento',
            format: 'UF %amount%'
        },
        CLP: {
            name: 'Chilean Peso',
            format: '$%amount%'
        },
        CNY: {
            name: 'Yuan Renminbi',
            format: '%amount% 元'
        },
        COP: {
            name: 'Colombian Peso',
            format: '$%amount%'
        },
        COU: {
            name: 'Unidad de Valor Real',
            format: '%amount%'
        },
        CRC: {
            name: 'Cost Rican Colon',
            format: '₡%amount%'
        },
        CUC: {
            name: 'Peso Convertible',
            format: 'CUC$ %amount%'
        },
        CUP: {
            name: 'Cuban Peso',
            format: '$MN%amount%'
        },
        CVE: {
            name: 'Cabo Verde Escudo',
            format: 'Esc %amount%'
        },
        CZK: {
            name: 'Czech Koruna',
            format: '%amount% Kč'
        },
        DJF: {
            name: 'Djibouti Franc',
            format: 'Fdj %amount%'
        },
        DKK: {
            name: 'Danish Krone',
            format: '%amount% kr'
        },
        DOP: {
            name: 'Dominican Peso',
            format: 'RD$%amount%'
        },
        DZD: {
            name: 'Algerian Dinar',
            format: '%amount% .د.ج'
        },
        EEK: {
            name: 'Estonian Kroon',
            format: 'kr%amount%'
        },
        EGP: {
            name: 'Egyptian Pound',
            format: '£%amount%'
        },
        ERN: {
            name: 'Nakfa',
            format: 'ናቕፋ %amount%'
        },
        ETB: {
            name: 'Ethiopian Birr',
            format: 'Br %amount%'
        },
        EUR: {
            name: 'Euro',
            format: '€%amount%'
        },
        FJD: {
            name: 'Fiji Dollar',
            format: '$%amount%'
        },
        FKP: {
            name: 'Falkland Islands Pound',
            format: '£%amount%'
        },
        GBP: {
            name: 'Pound Sterling',
            format: '£%amount%'
        },
        GEL: {
            name: 'Lari',
            format: '%amount%'
        },
        GGP: {
            name: 'Guernsey Pound',
            format: '£%amount%'
        },
        GHC: {
            name: 'Ghanaian Cedi',
            format: '¢%amount%'
        },
        GHS: {
            name: 'Ghan Cedi',
            format: 'GHS %amount%'
        },
        GIP: {
            name: 'Gibraltar Pound',
            format: '£%amount%'
        },
        GMD: {
            name: 'Dalasi',
            format: 'D %amount%'
        },
        GNF: {
            name: 'Guine Franc',
            format: 'FG %amount%'
        },
        GTQ: {
            name: 'Quetzal',
            format: 'Q%amount%'
        },
        GYD: {
            name: 'Guyan Dollar',
            format: '$%amount%'
        },
        HKD: {
            name: 'Hong Kong Dollar',
            format: '$%amount%'
        },
        HNL: {
            name: 'Lempira',
            format: 'L%amount%'
        },
        HRK: {
            name: 'Croatian Kuna',
            format: 'kn%amount%'
        },
        HTG: {
            name: 'Gourde',
            format: '%amount%'
        },
        HUF: {
            name: 'Forint',
            format: 'Ft%amount%'
        },
        IDR: {
            name: 'Rupiah',
            format: 'Rp%amount%'
        },
        ILS: {
            name: 'New Israeli Sheqel',
            format: '₪%amount%'
        },
        IMP: {
            name: 'Manx Pound',
            format: '£%amount%'
        },
        INR: {
            name: 'Indian Rupee',
            format: '₹%amount%'
        },
        IQD: {
            name: 'Iraqi Dinar',
            format: '%amount% .د.ع'
        },
        IRR: {
            name: 'Iranian Rial',
            format: '%amount% ﷼'
        },
        ISK: {
            name: 'Iceland Krona',
            format: 'kr%amount%'
        },
        JEP: {
            name: 'Jersey Pound',
            format: '£%amount%'
        },
        JMD: {
            name: 'Jamaican Dollar',
            format: 'J$%amount%'
        },
        JOD: {
            name: 'Jordanian Dinar',
            format: '%amount% .د.إ'
        },
        JPY: {
            name: 'Yen',
            format: '¥%amount%'
        },
        KES: {
            name: 'Kenyan Shilling',
            format: 'KSh%amount%'
        },
        KGS: {
            name: 'Som',
            format: 'сом%amount%'
        },
        KHR: {
            name: 'Riel',
            format: '៛%amount%'
        },
        KMF: {
            name: 'Comoro Franc',
            format: 'KMF %amount%'
        },
        KPW: {
            name: 'North Korean Won',
            format: '₩%amount%'
        },
        KRW: {
            name: 'Won',
            format: '₩%amount%'
        },
        KWD: {
            name: 'Kuwaiti Dinar',
            format: '%amount% .د.ك'
        },
        KYD: {
            name: 'Cayman Islands Dollar',
            format: '$%amount%'
        },
        KZT: {
            name: 'Tenge',
            format: '₸%amount%'
        },
        LAK: {
            name: 'Kip',
            format: '₭%amount%'
        },
        LBP: {
            name: 'Lebanese Pound',
            format: '£%amount%'
        },
        LKR: {
            name: 'Sri Lank Rupee',
            format: '₨%amount%'
        },
        LRD: {
            name: 'Liberian Dollar',
            format: '$%amount%'
        },
        LSL: {
            name: 'Loti',
            format: 'L %amount%'
        },
        LTL: {
            name: 'Lithuanian Litas',
            format: 'Lt%amount%'
        },
        LVL: {
            name: 'Latvian Lats',
            format: '%amount% Ls'
        },
        LYD: {
            name: 'Libyan Dinar',
            format: '%amount% .د.ل'
        },
        MAD: {
            name: 'Moroccan Dirham',
            format: '%amount% .د.م'
        },
        MDL: {
            name: 'Moldovan Leu',
            format: 'MDL %amount%'
        },
        MGA: {
            name: 'Malagasy riary',
            format: 'Ar %amount%'
        },
        MKD: {
            name: 'Denar',
            format: 'ден%amount%'
        },
        MMK: {
            name: 'Kyat',
            format: 'K %amount%'
        },
        MNT: {
            name: 'Tugrik',
            format: '₮%amount%'
        },
        MOP: {
            name: 'Pataca',
            format: '$%amount%'
        },
        MRO: {
            name: 'Ouguiya',
            format: 'CM %amount%'
        },
        MUR: {
            name: 'Mauritius Rupee',
            format: '₨%amount%'
        },
        MVR: {
            name: 'Rufiyaa',
            format: 'Rf %amount%'
        },
        MWK: {
            name: 'Kwacha',
            format: 'ZK %amount%'
        },
        MXN: {
            name: 'Mexican Peso',
            format: '$%amount%'
        },
        MXV: {
            name: 'Mexican Unidad de Inversion (UDI)',
            format: 'Mex$ %amount%'
        },
        MYR: {
            name: 'Malaysian Ringgit',
            format: 'RM%amount%'
        },
        MZN: {
            name: 'Mozambique Metical',
            format: 'MT%amount%'
        },
        NAD: {
            name: 'Namibi Dollar',
            format: '$%amount%'
        },
        NGN: {
            name: 'Naira',
            format: '₦%amount%'
        },
        NIO: {
            name: 'Cordob Oro',
            format: 'C$%amount%'
        },
        NOK: {
            name: 'Norwegian Krone',
            format: '%amount% kr'
        },
        NPR: {
            name: 'Nepalese Rupee',
            format: '₨%amount%'
        },
        NZD: {
            name: 'New Zealand Dollar',
            format: '$%amount%'
        },
        OMR: {
            name: 'Rial Omani',
            format: '%amount% ﷼'
        },
        PAB: {
            name: 'Balboa',
            format: 'B/.%amount%'
        },
        PEN: {
            name: 'Nuevo Sol',
            format: 'S/%amount%'
        },
        PGK: {
            name: 'Kina',
            format: 'K %amount%'
        },
        PHP: {
            name: 'Philippine Peso',
            format: '₱%amount%'
        },
        PKR: {
            name: 'Pakistan Rupee',
            format: '₨%amount%'
        },
        PLN: {
            name: 'Zloty',
            format: '%amount% zł'
        },
        PYG: {
            name: 'Guarani',
            format: '%amount%Gs'
        },
        QAR: {
            name: 'Qatari Rial',
            format: '%amount% ﷼'
        },
        RON: {
            name: 'New Romanian Leu',
            format: 'lei%amount%'
        },
        RSD: {
            name: 'Serbian Dinar',
            format: 'Дин.%amount%'
        },
        RUB: {
            name: 'Russian Ruble',
            format: '%amount% ₽'
        },
        RUR: {
            name: 'Russian Ruble',
            format: '%amount% ₽'
        },
        RWF: {
            name: 'Rwand Franc',
            format: 'FRw %amount%'
        },
        SAR: {
            name: 'Saudi Riyal',
            format: '%amount% ﷼'
        },
        SBD: {
            name: 'Solomon Islands Dollar',
            format: '$%amount%'
        },
        SCR: {
            name: 'Seychelles Rupee',
            format: '₨%amount%'
        },
        SDG: {
            name: 'Sudanese Pound',
            format: 'ج.س %amount%'
        },
        SEK: {
            name: 'Swedish Krona',
            format: '%amount% kr'
        },
        SGD: {
            name: 'Singapore Dollar',
            format: '$%amount%'
        },
        SHP: {
            name: 'Saint Helen Pound',
            format: '£%amount%'
        },
        SLL: {
            name: 'Leone',
            format: 'Le %amount%'
        },
        SOS: {
            name: 'Somali Shilling',
            format: 'S%amount%'
        },
        SRD: {
            name: 'Surinam Dollar',
            format: '$%amount%'
        },
        SSP: {
            name: 'South Sudanese Pound',
            format: 'SSP %amount%'
        },
        STD: {
            name: 'Dobra',
            format: 'Db %amount%'
        },
        SVC: {
            name: 'El Salvador Colon',
            format: '$%amount%'
        },
        SYP: {
            name: 'Syrian Pound',
            format: '£%amount%'
        },
        SZL: {
            name: 'Lilangeni',
            format: 'L %amount%'
        },
        THB: {
            name: 'Baht',
            format: '฿%amount%'
        },
        TJS: {
            name: 'Somoni',
            format: 'TJS %amount%'
        },
        TMT: {
            name: 'Turkmenistan New Manat',
            format: 'm %amount%'
        },
        TND: {
            name: 'Tunisian Dinar',
            format: '%amount% .د.ت'
        },
        TOP: {
            name: 'Pa’anga',
            format: 'T$ %amount%'
        },
        TRL: {
            name: 'Turkish Lira',
            format: '₤%amount%'
        },
        TRY: {
            name: 'Turkish Lira',
            format: '₺%amount%'
        },
        TTD: {
            name: 'Trinidad and Tobago Dollar',
            format: 'TT$%amount%'
        },
        TWD: {
            name: 'New Taiwan Dollar',
            format: 'NT$%amount%'
        },
        TZS: {
            name: 'Tanzanian Shilling',
            format: 'TSh%amount%'
        },
        UAH: {
            name: 'Hryvnia',
            format: '₴%amount%'
        },
        UGX: {
            name: 'Ugand Shilling',
            format: 'USh%amount%'
        },
        USD: {
            name: 'US Dollar',
            format: '$%amount%'
        },
        USN: {
            name: 'US Dollar (Next day)',
            format: '%amount%'
        },
        UYI: {
            name: 'Uruguay Peso en Unidades Indexadas (URUIURUI)',
            format: 'UYI %amount%'
        },
        UYU: {
            name: 'Peso Uruguayo',
            format: '$U%amount%'
        },
        UZS: {
            name: 'Uzbekistan Sum',
            format: 'so’m%amount%'
        },
        VEF: {
            name: 'Bolivar',
            format: 'Bs%amount%'
        },
        VND: {
            name: 'Dong',
            format: '%amount% ₫'
        },
        VUV: {
            name: 'Vatu',
            format: 'VT %amount%'
        },
        WST: {
            name: 'Tala',
            format: 'WS$ %amount%'
        },
        XAF: {
            name: 'CF Franc BEAC',
            format: 'FCFA %amount%'
        },
        XCD: {
            name: 'East Caribbean Dollar',
            format: '$%amount%'
        },
        XDR: {
            name: 'SDR (Special Drawing Right)',
            format: 'SDR %amount%'
        },
        XOF: {
            name: 'CF Franc BCEAO',
            format: 'CFA %amount%'
        },
        XPF: {
            name: 'CFP Franc',
            format: 'CFP %amount%'
        },
        XSU: {
            name: 'Sucre',
            format: 'Sucre %amount%'
        },
        XUA: {
            name: 'ADB Unit of Account',
            format: '%amount%'
        },
        YER: {
            name: 'Yemeni Rial',
            format: '%amount% ﷼'
        },
        ZAR: {
            name: 'Rand',
            format: 'R%amount%'
        },
        ZMW: {
            name: 'Zambian Kwacha',
            format: 'ZK %amount%'
        },
        ZWD: {
            name: 'Zimbabwe Dollar',
            format: 'Z$%amount%'
        },
        ZWL: {
            name: 'Zimbabwe Dollar',
            format: 'Z$%amount%'
        }
    };

    common.getCurrencyFormatted = function( currencyISO, amount, rounded ) {
        if ( CURRENCIES[ currencyISO ] ) {
            if ( rounded ) {
                return CURRENCIES[ currencyISO ].format.replace( '%amount%', Math.round( amount ) );
            }
            return CURRENCIES[ currencyISO ].format.replace( '%amount%', amount.toFixed( 2 ) );
        }
    };

    common.getCurrencyIcon = function( currencyISO ) {
        if ( CURRENCIES[ currencyISO ] ) {
            return CURRENCIES[ currencyISO ].format.replace( '%amount%', '' );
        }
    };

    common.getCurrencyName = function( currencyISO ) {
        if ( CURRENCIES[ currencyISO ] ) {
            return CURRENCIES[ currencyISO ].name;
        }
    };

} ( PULSE.app.common ) );