( function( core, common ) {

    common.filterOptions = function filterOptions( filters, references ) {
        const _self = this;

        _self.filters = filters;
        _self.updateReferences( references );
    };

    common.filterOptions.prototype.addRefsToFilters = function addRefsToFilters() {
        const _self = this;

        let refString = '';
        if ( typeof _self.references === 'string' ) {
            refString += _self.references;
        } else {
            for ( var key in _self.references ) {
                // skip loop if the property is from prototype
                if ( !_self.references.hasOwnProperty( key ) ) { continue; }
                if ( refString.length > 0 ) {
                    refString += ',';
                }
                refString += key + ':' + _self.references[ key ];
            }
        }

        _self.filters.references = refString;
    };

    common.filterOptions.prototype.updateFilters = function updateFilters( newFilters ) {
        const _self = this;
        _self.filters = core.object.extend( _self.filters, newFilters );
    };

    common.filterOptions.prototype.updateReferences = function updateReferences( newReferences ) {
        const _self = this;

        if ( typeof newReferences !== 'undefined' ) {
            if ( !_self.references || typeof _self.references === 'string' ) {
                _self.references = newReferences;
            } else {
                _self.references = core.object.extend( _self.references, newReferences );
            }

            _self.addRefsToFilters();
        }
    };

    common.filterOptions.prototype.removeReference = function removeReference( reference ) {
        const _self = this;

        delete _self.references[ reference ];
        _self.addRefsToFilters();
    };

    common.filterOptions.prototype.get = function get() {
        const _self = this;
        return _self.filters;
    };

} ( PULSE.core, PULSE.app.common ) );
