/* eslint-disable complexity */
( function ( core, app, common ) {

    const { TAGS } = common.constants;

    /**
     * creates content path based on the type and parameters
     * @param {String} type Type of content
     * @param {Object} params query params (to be used as GET params)
     * @param {String} lang   the API language; defaults to english
     * @param {Number} id     ID of the item if requesting only one
     * @returns {String} url Content url
     */
    common.createContentPath = function ( type, params, lang, id ) {
        var contentType = type ? type.toLowerCase() + '/' : '';
        var url = app.environment.api + '/content/' + app.account + '/' + contentType + ( lang ? lang : app.defaultLanguage ) + '/' + ( id ? id : '' );

        if ( params ) {
            url += '?' + core.url.buildQueryString( params );
        }

        return url;
    };

    /**
     * creates API path based on a string and parameters
     * @param {String} path Object path for the API object
     * @param {Object} params Url parameters
     * @returns {String} url API url
     */
    common.createApiPath = function ( path, params ) {
        var thisPath = core.object.objectByString( app.environment.api, path );
        var paramArray = [];
        for ( var key in params ) {
            if ( thisPath.indexOf( '{' + key + '}' ) > -1 ) {
                thisPath = thisPath.replace( '{' + key + '}', params[ key ] );
            } else if ( typeof params[ key ] !== 'undefined' ) {
                paramArray.push( key + '=' + params[ key ] );
            }
        }
        var url = thisPath;
        if ( paramArray.length > 0 ) {
            url += '?' + paramArray.join( '&' );
        }
        return url;
    };

    /**
     * generate a url for a some content
     * @param {String} type            Type of content
     * @param {Object|Number} content  Id of content or the content itself
     * @param {String} restriction     For playlist, if there's a content restriction
     * @returns {String} url Link to content
     */
    common.generateUrl = function ( type, content, restriction ) {
        const domain = window.location.hostname;
        var base = '//' + domain;
        var contentType = ( type || '' ).toLowerCase();
        var contentResponse, url, id;

        // this is ugly
        if ( typeof content === 'object' && content.id ) {
            contentResponse = content;
            id = content.id;
        } else {
            id = content;
        }

        var playlistRestriction = restriction ? restriction.toLowerCase() : '';

        switch ( contentType ) {
            case 'text': {

                let path;
                content.tags.forEach( tag => {
                    const label = tag.label;
                    if ( label.includes( 'content-types:' ) ) {
                        path = label.replace( 'content-types:', '' );
                    }
                } );
                if ( path ) {
                    url = base + `/${ path }/` + id;
                } else {
                    url = base + '/blog/' + id;
                }

                if ( contentResponse && contentResponse.title ) {
                    return url + '/' + common.urlify( contentResponse.title );
                }

                return url;
            }
            case 'video':
                url = base + '/video/' + id;
                if ( contentResponse && contentResponse.title ) {
                    return url + '/' + common.urlify( contentResponse.title );
                }

                return url;

            case 'playlist':
                if ( contentResponse && common.content.hasTag( contentResponse, TAGS.ITINERARY ) ) {
                    return `//${ app.environment.mapDomain}/itineraries/${ contentResponse.id }/${ common.urlify( contentResponse.title ) }`;
                } else if ( playlistRestriction === 'photo' ) {
                    url = base + '/photos/' + id;
                    if ( contentResponse && contentResponse.title ) {
                        return url + '/' + common.urlify( contentResponse.title );
                    }

                    return url;

                } else if ( playlistRestriction === 'video' ) {
                    return base + '/video/categories/' + id;
                }
                return '';
            case 'photo':
                url = '/' + id;
                if ( contentResponse && contentResponse.description ) {
                    return url + '/' + common.urlify( contentResponse.description );
                }

                return url;
            default:
                return '';
        }
    };

    common.urlify = function ( str ) {
        return str
            .toLowerCase()
            .replace( /[^a-z0-9]+/gi, '-' )
            .replace( /-{2,}/g, '-' )
            .replace( /^-|-$/g, '' );
    };

}( PULSE.core, PULSE.app, PULSE.app.common ) );
