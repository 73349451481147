/*eslint new-cap: ["error", { "newIsCapExceptions": ["filterOptions"] }] */

( function( app, core, common ) {
    'use strict';

    /**
     * Load More
     *
     * Load more content via an API request. Will used passed parameters in
     * config to build 'filters' and make correct API request.
     *
     * @param { Object } config - configure the request LoadMore will make.
     * Generally powered by data attributes. Example config:
     *
     * config = {
     *     pageSizeLoadMore: widget.dataset.page-size-loadmore,
     *     pageSize: widget.dataset.page-size,
     *     defaultTags: widget.dataset.tags,
     *     references: widget.dataset.references
     *     playlistTypeRestriction: widget.dataset.playlist-restriction,
     *     contentTypes: widget.dataset.contentTypes.split(',')
     * }
     */
    app.LoadMore = function LoadMore( config ) {
        const _self = this;
        const filters = {
            page: parseInt( config.page, 10 ),
            pageSize: parseInt( config.pageSizeLoadMore || config.pageSize, 10 ),
            tagNames: config.defaultTags,
            references: config.references,
            referenceExpression: config.referenceExpression,
            playlistTypeRestriction: config.playlistTypeRestriction,
            publishedAfter: config.publishedAfter,
            publishedBefore: config.publishedBefore
        };

        _self.filters = new common.filterOptions( filters );
        _self.language = config.language || app.defaultLanguage;
        _self.contentTypes = config.contentTypes;
    };

    /**
     * Get Content
     *
     * @param { Function } callback - Will be called with XHR data when the
     * request has been resolved.
     *
     * @callback callback
     */
    app.LoadMore.prototype.getContent = function getContent( callback ) {
        const _self = this;
        const query = _self.buildQuery();

        let requestConfig = {
            url: query.endpoint,
            method: 'GET',
            callback: data => {
                _self.handleResponse( data, callback );
            },
            forceCallback: true,
            target: _self
        };

        core.data.manager.add( requestConfig );
    };

    /**
     * Build Query
     *
     * Get latest filter parameters (as they may have changed since the last
     * request) and then construct the query string for the API request.
     *
     * @returns { Object } query - the query object
     * @returns { Object } query.filterParams - the latest filter params
     * @returns { String } query.type - the query type (if applicable)
     * @returns { String } query.endpoint - the constructed query string
     */
    app.LoadMore.prototype.buildQuery = function buildQuery() {
        const _self = this;
        const query = {};

        // Get up to date filter parameters
        query.filterParams = _self.filters.get();
        query.type = '';
        if ( _self.contentTypes.length > 1 ) {
            query.filterParams.contentTypes = _self.contentTypes.map( type => type.toLowerCase() );
        } else {
            query.type = ( _self.contentTypes[ 0 ] || '' ).toLowerCase();
        }

        query.endpoint = app.common.createContentPath( query.type, query.filterParams, _self.language );

        return query;
    };


    /**
     * Handle Response
     *
     * Always called after the XHR request to the API. Job is to update filters
     * for the next request.
     *
     * @param { Object } data - data back from the API
     * @param { Function } callback - callback function will get passed `data`
     * object even if the object is empty.
     */
    app.LoadMore.prototype.handleResponse = function handleResponse( data, callback ) {
        const _self = this;

        if ( typeof data.content === 'undefined' || data.content.length === 0 ) {
            console.warn( 'Data was not received from API' );
        }

        _self.filters.updateFilters( { page: _self.filters.get().page + 1 } );

        callback( data );
    };

} ( PULSE.app, PULSE.core, PULSE.app.common ) );
