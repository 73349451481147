( function( common ) {


    common.EVENT = {
        /**
         * Define the events that will be fired at various points on the body element
         * other widgets can interact with the player through these events
         * Must be defined here as is acessed by multiple separate widgets
         *
         */

        MAP: {
            // Event fired when a the map view is switched
            VIEW_SWITCH: 'app.ViewSwitcher.viewSwitched',
            // Event fired when the route is loaded
            ROUTE_LOADED: 'app.MapWidget.routeLoaded',
            // Event fired when a POI is selected
            POI_SELECTED: 'app.MapWidget.poiSelected'
        },

        MAP_PLAN: {
            // Map Plan Route Widget
            // When both starting point and destination have been inputted
            ROUTE_SET: 'app.MapPlanRoute.routeSet',
            // Map Plan Sidebar Widget
            // Passes the ID of the POI that has been added
            POI_ADDED: 'app.MapPlanSidebar.poiAdded',
            // Passes the ID of the POI that has been selected
            POI_SELECTED: 'app.MapPlanSidebar.poiSelected',
            // Passes the POIs to the calculated
            CALCULATE_ROUTE: 'app.MapPlanSidebar.calculateRoute',
            // Event to show the route is being edited
            EDIT_ROUTE: 'app.MapPlanSidebar.editRoute',
            // event fired when the route is loaded
            ROUTE_LOADED: 'app.MapPlanSidebar.routeLoaded',
            // Event to show the route is being edited
            UPDATE_ITINERARY: 'app.MapPlanSidebar.updateItinerary',
            // Event to show the tab has changed
            CHANGE_TAB: 'app.MapPlanSidebar.changeTab',
            // Map Plan Widget
            // Passes the search result
            POIS: 'app.MapPlanSidebar.pois',
            // Event to indicate that POIs are being loaded
            LOAD_POIS: 'app.MapPlanSidebar.LOAD_POIS'
        }
    };

} ( PULSE.app.common ) );
