/* eslint-disable max-lines */

( function( common ) {
    'use strict';

    /**
     * Global Constants Object
     *
     * All global constants should be defined inside this object. A recommended
     * approach is to group constants that are specific to widgets under that
     * widget's constructor name.
     */
    if ( typeof common.constants === 'undefined' ) {
        common.constants = {};
    }

    common.constants.Z_INDEX = {
        LEVEL_ONE: 100
    };

    common.constants.TAGS = {
        ITINERARY: 'itinerary'
    };

    /**
     * All glboal InfiniteScrollWidget constants should be defined inside this
     * object.
     */
    common.constants.InfiniteScrollWidget = {
        READY: 'app.InfiniteScrollWidget.ready'
    };

} ( PULSE.app.common ) );
