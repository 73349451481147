( function( app, core ) {
    'use strict';

    /* eslint-disable no-magic-numbers  */

    var privacyMessage;
    var expiry = 1 * 60 * 24 * 100; // 100 days

    var checkPrivacyPolicy = function() {
        var cookie;

        cookie = core.localStorage.getStorage( 'cookie-privacy_policy', true );

        if ( !cookie ) {
            displayPPMessage();
        }
    };

    var displayPPMessage = function() {
        privacyMessage = document.querySelector( '.js-cookie-notice' );

        if ( privacyMessage ) {
            core.style.removeClass( privacyMessage, 'cookie-notice--hide' );

            var button = privacyMessage.querySelector( '.js-cookie-notice-btn' );
            if ( button ) {
                button.addEventListener( 'click', acceptPolicy );
            }
        }
    };

    var acceptPolicy = function() {

        core.localStorage.setStorage( 'cookie-privacy_policy', 'accepted', expiry, true, getDomain() );

        privacyMessage.classList.add( 'cookie-notice--hide' );
    };

    var getDomain = function() {
        var hostName = window.location.hostname;
        var domain = hostName;
        
        if ( hostName !== null ) {
            var parts = hostName.split( '.' ).reverse();
            
            if ( parts !== null && parts.length > 1 ) {
                domain = parts[ 1 ] + '.' + parts[ 0 ];
                    
                if ( hostName.toLowerCase().indexOf( '.co.uk' ) !== -1 && parts.length > 2 ) {
                    domain = parts[ 2 ] + '.' + domain;
                }
            }
        }
        
        return domain;
    };

    checkPrivacyPolicy();

}( PULSE.app, PULSE.core ) );
