/**
 * Pulse Platform Endpoints
 *
 * Global football API endpoints!
 *
 * Docs are here: http://swagger.pulselive.com/
 */
( function( common ) {
    'use strict';

    if ( typeof common.endpoints === 'undefined' ) {
        common.endpoints = {};
    }

    const prepareParams = common.prepareParams;

    common.endpoints.football = {
        awards: {
            historic: ( cid, aid, params ) => `/football/competitions/${cid}/awards/${aid}${ prepareParams( params ) }`,
            compSeason: ( id, params ) => `/football/compseasons/${id}/awards${ prepareParams( params ) }`,
            season: ( id, params ) => `/football/seasons/${id}/awards${ prepareParams( params ) }`,
        },
        clubs: {
            all: params => `/football/clubs${ prepareParams( params ) }`,
            single: ( id, params ) => `/football/clubs/${id}${ prepareParams( params ) }`,
        },
        competition: {
            all: params => `/football/competitions${ prepareParams( params ) }`,
            single: ( abbr, params ) => `/football/competitions/${abbr}${ prepareParams( params ) }`,
            compseason: ( id, params ) => `/football/competitions/${id}/compseasons${ prepareParams( params ) }`,
            current: ( id, params ) => `/football/competitions/${id}/compseasons/current${ prepareParams( params ) }`
        },
        competitionSeason: {
            single: ( id, params ) => `/football/compseasons/${id}${ prepareParams( params ) }`,
            gameweeks: {
                all: ( id, params ) => `/football/compseasons/${id}/gameweeks${ prepareParams( params ) }`,
                single: ( id, gameweek, params ) => `/football/compseasons/${id}/gameweeks/${gameweek}${ prepareParams( params ) }`,
                current: ( id, params ) => `/football/compseasons/${id}/gameweeks/current${ prepareParams( params ) }`,
                events: {
                    single: ( id, gameweek, params ) => `/football/compseasons/${id}/gameweeks/${gameweek}/events${ prepareParams( params ) }`,
                    current: ( id, params ) => `/football/compseasons/${id}/gameweeks/current/events${ prepareParams( params ) }`
                },
                fixtures: ( id, params ) => `/football/compseasons/${id}/gameweeks/current/fixtures${ prepareParams( params ) }`
            },
            standings: {
                all: ( id, params ) => `/football/compseasons/${id}/standings${ prepareParams( params ) }`,
                team: ( id, teamId, params ) => `/football/compseasons/${id}/standings/team/{teamId}${ prepareParams( params ) }`
            },
            structure: id => `/football/compseasons/${id}/structure`,
            teams: {
                all: ( id, params ) => `/football/compseasons/${id}/teams${ prepareParams( params ) }`,
                single: ( id, teamId, params ) => `/football/compseasons/${id}/teams/{teamId}${ prepareParams( params ) }`,
                performance: ( id, teamId, params ) => `/football/compseasons/${id}/teams/{teamId}/performance${ prepareParams( params ) }`
            }
        },
        fixtures: {
            all: params => `/football/fixtures${ prepareParams( params ) }`,
            headtohead: params => `/football/fixtures/headtohead${ prepareParams( params ) }`,
            single: ( id, params ) => `/football/fixtures/${id}${ prepareParams( params ) }`,
            summary: ( id, params ) => `/football/fixtures/${id}/summary${ prepareParams( params ) }`,
            textstream: ( id, lang, params ) => `/football/fixtures/${id}/textstream/${lang}${ prepareParams( params ) }`
        },
        gameweeks: {
            single: ( id, params ) => `/football/gameweeks/${id}${ prepareParams( params ) }`
        },
        grounds: {
            all: params => `/football/grounds${ prepareParams( params ) }`,
            single: ( id, params ) => `/football/grounds/${id}${ prepareParams( params ) }`
        },
        players: {
            all: params => `/football/players${ prepareParams( params ) }`,
            single: ( id, params ) => `/football/players/${id}${ prepareParams( params ) }`,
            history: ( id, params ) => `/football/players/${id}/history${ prepareParams( params ) }`
        },
        season: {
            all: params => `/football/seasons${ prepareParams( params ) }`
        },
        standings: {
            all: params => `/football/standings${ prepareParams( params ) }`
        },
        stats: {
            headtohead: params => `/football/stats/headtohead${ prepareParams( params ) }`,
            match: ( id, params ) => `/football/stats/match/${id}${ prepareParams( params ) }`,
            player: ( id, params ) => `/football/stats/player/${id}${ prepareParams( params ) }`,
            playerFixtures: ( id, params ) => `/football/stats/player/${id}/fixtures${ prepareParams( params ) }`,
            ranked: {
                players: ( statistic, params ) => `/football/stats/ranked/players/${statistic}${ prepareParams( params ) }`,
                teams: ( statistic, params ) => `/football/stats/ranked/teams/${statistic}${ prepareParams( params ) }`
            },
            team: ( id, params ) => `/football/stats/team/${id}${ prepareParams( params ) }`
        },
        teams: {
            all: params => `/football/teams${ prepareParams( params ) }`,
            single: {
                team: ( id, params ) => `/football/teams/${id}${ prepareParams( params ) }`,
                staff: ( id, compSeasonId, params ) => `/football/teams/${id}/compseasons/${compSeasonId}/staff${ prepareParams( params ) }`,
                stats: ( id, compSeasonId, params ) => `/football/teams/${id}/compseasons/${compSeasonId}/stats${ prepareParams( params ) }`,
                seasonStaff: ( id, seasonId, params ) => `/football/teams/${id}/seasons/${seasonId}/staff${ prepareParams( params ) }`
            }
        },
        teamOfficials: {
            all: params => `/football/teamofficials${ prepareParams( params ) }`,
            single: ( id, params ) => `/football/teamofficials/${id}${ prepareParams( params ) }`
        },
        broadcastingSchedule: {
            countries: () => '/broadcasting-schedule/countries',
            all: params => `/football/broadcasting-schedule/fixtures${ prepareParams( params ) }`,
            single: ( id, params ) => `/football/broadcasting-schedule/fixtures/${id}${ prepareParams( params ) }`,
            highlights: params => `/football/broadcasting-schedule/highlights-programs${ prepareParams( params ) }`
        },
        matchOfficials: {
            all: params => `/football/matchofficials${ prepareParams( params ) }`,
            single: ( id, params ) => `/football/matchofficials/${id}${ prepareParams( params ) }`
        }
    };

}( PULSE.app.common ) );
