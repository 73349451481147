( function ( common ) {
    'use strict';

    const DEFAULT_DURATION = 200;
    const DEFAULT_EASING = 'easeInQuad';

    common.scrollTo = function ( destination, offset = 0, duration = DEFAULT_DURATION, easing = DEFAULT_EASING, callback ) {

        const easings = {
            linear( time ) {
                return time;
            },
            easeInQuad( time ) {
                return time * time;
            }
            // example
            // easeInQuad( tim ) {
            //     return time * time;
            // }
        };
      
        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
      
        const documentHeight = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
        const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName( 'body' )[ 0 ].clientHeight;
        const destinationOffset = typeof destination === 'number' ? destination : destination.getBoundingClientRect().top + ( ( window.pageYOffset || document.documentElement.scrollTop ) - ( document.documentElement.clientTop || 0 ) ) - offset;
        const destinationOffsetToScroll = Math.round( documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset );
      
        if ( 'requestAnimationFrame' in window === false ) {
            window.scroll( 0, destinationOffsetToScroll );
            if ( callback ) {
                callback();
            }
            return;
        }
      
        function scroll() {
            const now = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min( 1, ( ( now - startTime ) / duration ) );
            const timeFunction = easings[ easing ]( time );
            window.scroll( 0, Math.ceil( ( timeFunction * ( destinationOffsetToScroll - start ) ) + start ) );
      
            if ( window.pageYOffset === destinationOffsetToScroll ) {
                if ( callback ) {
                    callback();
                }
                return;
            }
      
            requestAnimationFrame( scroll );
        }
      
        scroll();
    };
      

}( PULSE.app.common ) );
