( function( common ) {
    'use strict';

    common.ArticleTable = function( container ) {
        this.container = container;
        this.tables = this.container.querySelectorAll( 'table' );

        for ( let i = 0; i < this.tables.length; i++ ) {
            if ( this.container.className === this.tables[ i ].parentElement.className ) {
                this.tables[ i ].outerHTML = '<div class="table-scrollable">' + this.tables[ i ].outerHTML + '</div>';
            }
        }
    };

    // initialise on article pages
    const articles = document.querySelectorAll( '.js-article-content' );

    
    Array.prototype.map.call( articles, function( article ) {
        new common.ArticleTable( article );
    } );
} ( PULSE.app.common ) );