( function( common ) {
    'use strict';

    let errors = [];

    /**
     * Validates config object based on passed tests
     *
     * @param {string} constructorName - the namespace of the constructor calling this method
     * @param {Object} config - the full config object to be validated
     * @param {Object[]} tests - the array of tests to be run against the config object
     * @param {string} tests[].namespace - The name of the property in the configuration object
     * @param {string} [tests[].selector] - Use if the property should be assigned a DOMElement. Any CSS selector.
     * @param {string} [tests[].elementType] - What interface should the DOMElement be? E.g. NodeList, HTMLElement
     * @returns {Boolean} - True if config was valid
     *
     * Example Tests Object
     *
     * tests = [
     *      {
     *          namespace: 'container',
     *          elementType: HTMLElement
     *      },
     *      {
     *          namespace: 'elements',
     *          elementType: NodeList
     *      },
     *
     * ]
     */
    common.validateConfig = function validateConfig( constructorName, config = {}, tests = [] ) {
        tests.forEach( testCase => {
            // Does the namespace exist?
            namespaceExists( testCase, config );
            // Does the element exist ?
            elementExists( testCase, config );
            // Is the element the correct type?
            correctType( config[ testCase.namespace ], testCase );
        } );

        if ( errors.length > 0 ) {
            errors.unshift( `You have ${ errors.length } errors initialising ${ constructorName }.` );
            console.warn( errors.join( '\n' ) );
            return false;
        }

        return true;
    };

    const namespaceExists = ( testCase, config ) => {
        if ( config[ testCase.namespace ] ) {
            return true;
        }

        errors.push( `Missing configuration property. config.${ testCase.namespace } is required and should be of type ${ testCase.elementType }` );
        return false;
    };

    const elementExists = ( testCase, config ) => {
        if ( typeof testCase.namespace === 'undefined' || testCase.elementType === 'undefined' ) {
            return;
        }

        let element = config[ testCase.namespace ];

        if ( typeof element !== 'object' ) {
            errors.push( `Could not find ${ testCase.elementType.name } under config.${ testCase.namespace }` );
            return;
        }

        if ( typeof element.length !== 'undefined' && element.length === 0 ) {
            errors.push( `Empty ${ testCase.elementType.name } under config.${ testCase.namespace }` );
            return;
        }
    };

    const correctType = ( element, testCase ) => {
        if ( typeof element === 'undefined' ) {
            return;
        }

        if ( !( element instanceof testCase.elementType ) ) {
            errors.push( `Incorrect type. Expected config.${ testCase.namespace } to be instance of ${ testCase.elementType.name }` );
        }
    };


} ( PULSE.app.common ) );
