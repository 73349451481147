/* eslint-disable id-length */

( function( common ) {

    'use strict';

    const EXPEDIA = 'expedia';
    const HOTELS = 'hotels';
    const COUNTRIES = {
        US: 'us',
        CA: 'ca',
        UK: 'uk',
        DE: 'de',
        FR: 'fr',
        ES: 'es',
        IT: 'it'
    };
    const EXPEDIA_URLS = {
        US: 'https://www.expedia.com',
        CA: 'https:///www.expedia.ca',
        UK: 'https://www.expedia.co.uk',
        DE: 'https://www.expedia.de',
        FR: 'https://www.expedia.fr',
        ES: 'https://www.expedia.es',
        IT: 'https://www.expedia.it'
    };
    const NUMBER_OF_WEEKS = 2;

    /**
     * @module ExpediaHelpers
     *
     * A combination of utilties to use when working with Expedia Data.
     * @type {Object}
     */
    common.expedia = {};

    /**
     * Map expedia id to hotels id
     *
     * @param {String} expediaId - The expedia id
     * @returns {String} - The hotels id
     */
    common.expedia.mapIdToHotelsId = ( expediaId ) => {
        const region = common.constants.REGIONS.filter( element => element.expediaId === expediaId )[ 0 ];
        if ( region ) {
            return region.hotelsId;
        }
        return '';
    };

    /**
     * Get POS hotel listing url
     *
     * @param {String} pos - The POS
     * @param {String} country - The country
     * @param {String} id - The id
     * @returns {String} - The url
     */
    common.expedia.getPosHotelListing = ( pos, country, id ) => {
        let url = '';
        const startDate = moment().add( NUMBER_OF_WEEKS, 'weeks' ).format( 'YYYY-MM-DD' );
        const endDate = moment( startDate ).add( 1, 'days' ).format( 'YYYY-MM-DD' );
        if ( pos === EXPEDIA ) {
            if ( country === COUNTRIES.US ) {
                url = EXPEDIA_URLS.US;
            } else if ( country === COUNTRIES.CA ) {
                url = EXPEDIA_URLS.CA;
            } else if ( country === COUNTRIES.UK ) {
                url = EXPEDIA_URLS.UK;
            } else if ( country === COUNTRIES.DE ) {
                url = EXPEDIA_URLS.DE;
            } else if ( country === COUNTRIES.FR ) {
                url = EXPEDIA_URLS.FR;
            } else if ( country === COUNTRIES.ES ) {
                url = EXPEDIA_URLS.ES;
            } else if ( country === COUNTRIES.IT ) {
                url = EXPEDIA_URLS.IT;
            }
        }
        url += `/Hotel-Search?regionId=${ id }&startDate=${ startDate }&endDate=${ endDate }`;
        return url;
    };

    /**
     * Get expedia details page
     *
     * @param {String} country - The country
     * @param {String} id - The id
     * @returns {String} - The hotels id
     */
    common.expedia.getExpediaDetailsPage = ( country, id ) => {
        let url = '';
        const startDate = moment().add( NUMBER_OF_WEEKS, 'weeks' ).format( 'YYYY-MM-DD' );
        const endDate = moment( startDate ).add( 1, 'days' ).format( 'YYYY-MM-DD' );
        if ( country === COUNTRIES.US ) {
            url = EXPEDIA_URLS.US;
        } else if ( country === COUNTRIES.CA ) {
            url = EXPEDIA_URLS.CA;
        } else if ( country === COUNTRIES.UK ) {
            url = EXPEDIA_URLS.UK;
        } else if ( country === COUNTRIES.DE ) {
            url = EXPEDIA_URLS.DE;
        } else if ( country === COUNTRIES.FR ) {
            url = EXPEDIA_URLS.FR;
        } else if ( country === COUNTRIES.ES ) {
            url = EXPEDIA_URLS.ES;
        } else if ( country === COUNTRIES.IT ) {
            url = EXPEDIA_URLS.IT;
        }
        url += `/h${ id }.Hotel-Information?chkin=${ startDate }&chkout=${ endDate }`;
        return url;
    };

    /**
     * Map name to id
     *
     * @param {String} name - The name of the region
     * @param {String} pos - Either the hotels or expedia
     * @returns {String} - Either the hotels id or expedia id
     */
    common.expedia.mapNameToId = ( name, pos ) => {
        const region = common.constants.REGIONS.filter( element => element.name === name )[ 0 ];
        if ( region ) {
            if ( pos === EXPEDIA ) {
                return region.expediaId;
            } else if ( pos === HOTELS ) {
                return region.hotelsId;
            }
        }
        return '';
    };

}( PULSE.app.common ) );
