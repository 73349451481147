/**
 * Coupled set of date string and moment object
 * @typedef {object} momentCouple
 * @property {moment|Date} date the moment Object
 * @property {string} string the generated string
 * @property {string} std locale independent date string ( DD-MM-YYYY )
 */

( function( common, app, core, moment ) {

    const SECS = 60;
    const MINS = 60;
    const HOURS = 24;
    const toMillis = 1000;
    const oneDay = ( ( SECS * MINS ) * HOURS ) * toMillis;

    /**
     * Use momentJS to get a locale-observant string with a specified format
     * will return moment object as well as string
     *
     * @param { Mixed } start - unix timestamp or Date
     * @param { Mixed } end - unix timestamp or Date
     * @param { String } format - date representation
     * @param {boolean} inclusive if true will include start  day in response
     * @returns {momentCouple[]} array of days with moment day and string included in each index position
     */
    common.momentGetDaysFromRange = function( start, end, format, inclusive ) {

        var startAsDate = start instanceof Date ? start : new Date( start );
        var endAsDate = end instanceof Date ? end : new Date( end );

        var startTime = startAsDate.getTime();

        var days = [];

        var current = startTime;

        if ( inclusive && ( startAsDate.toLocaleDateString() !== endAsDate.toLocaleDateString() ) ) {

            days.push( {
                date: startAsDate,
                string: moment && format ? moment( startAsDate ).locale( app.language ).format( format ) : startAsDate.toLocaleDateString(),
                std: moment ? moment( startAsDate ).format( 'DD-MM-YYYY' ) : false
            } );
        }

        // add a day until the date reaches the end date
        do {

            current = current + oneDay;
            var asDate = new Date( current );

            days.push( {
                date: asDate,
                string: moment && format ? moment( asDate ).locale( app.language ).format( format ) : asDate.toLocaleDateString(),
                std: moment ? moment( asDate ).format( 'DD-MM-YYYY' ) : false
            } );

        } while ( current < endAsDate.getTime() );

        return days;

    };

    /**
     * get the time from a date
     *
     * @param {String} date date string
     *
     * @returns { String } date as time
     */
    common.getTime = function( date ) {

        if ( date ) {
            var obj = typeof date === 'string' ? new Date( date ) : date;
            var hours = obj.getHours();
            var minutes = obj.getMinutes();
            const format = 10;

            if ( hours < format ) { hours = '0' + hours; }
            if ( minutes < format ) { minutes = '0' + minutes; }

            return hours + ':' + minutes;
        }

        return null;
    };

    /**
     * Get the time since a specific date
     *
     * @param {Date} date - Date to be calculated from now
     * @param {Object} format - Optional format for output
     * @returns {String} output Amount of time since date
     */
    common.getSinceString = function( date, format ) {
        if ( date ) {
            let now = new Date();
            let diff = Math.floor( ( now - date ) / toMillis );
            let output;

            if ( diff <= 0 ) {
                return format ? format.justNow : 'just now';
            } else if ( diff < MINS ) {
                output = Math.round( diff );
                return output + ( format ? format.seconds : 's' );
            } else if ( diff < MINS * SECS ) {
                output = Math.round( diff / MINS );
                return output + ( format ? format.minutes : 'm' );
            } else if ( diff < MINS * SECS * HOURS ) {
                output = Math.round( diff / ( MINS * SECS ) );
                return output + ( format ? format.hours : 'h' );
            }

            output = Math.round( diff / ( MINS * SECS * HOURS ) );
            return output + ( format ? format.days : 'd' );

        }

        return '';
    };

    /**
     * Get duration in time format mm:ss
     *
     * @param {Int} duration - number of seconds
     * @returns {String} output duration in format mm:ss
     */
    common.durationToTime = function( duration ) {
        var secNum = parseInt( duration, 10 );
        const hourInSecs = 3600;

        if ( secNum ) {
            var hours = Math.floor( secNum / hourInSecs );
            var minutes = Math.floor( ( secNum - ( hours * hourInSecs ) ) / SECS );
            var seconds = secNum - ( hours * hourInSecs ) - ( minutes * SECS );
            const format = 10;

            if ( hours < format ) { hours = '0' + hours; }
            if ( minutes < format ) { minutes = '0' + minutes; }
            if ( seconds < format ) { seconds = '0' + seconds; }

            var minSec = minutes + ':' + seconds;

            return hours > 0 ? hours + ':' + minSec : minSec;
        }

        return '00:00';
    };

    /**
     * Given a number of minutues, returns an appropriate breakdown of days, hours and minutes.
     *
     * @param {Int} minutes - the amount of minutes
     * @returns {String} - the breakdown of days, hours and minutes
     */
    common.minutesToDaysHoursMinutes = function ( minutes ) {

        let result = '';

        if ( minutes ) {
            
            let days = Math.floor( minutes / ( HOURS * MINS ) );
            let hours = Math.floor( ( minutes % ( HOURS * MINS ) ) / MINS );
            let mins = Math.floor( minutes % MINS );

            if ( days !== 0 ) {
                result += days;
                if ( days === 1 ) {
                    result += 'day ';
                } else {
                    result += 'days ';
                }
            }

            if ( hours !== 0 ) {
                result += hours;
                if ( hours === 1 ) {
                    result += 'hr ';
                } else {
                    result += 'hrs ';
                }
            }

            if ( mins !== 0 ) {
                result += mins;
                if ( mins === 1 ) {
                    result += 'min ';
                } else {
                    result += 'mins ';
                }
            }
        }

        return result;

    };

} ( PULSE.app.common, PULSE.app, PULSE.core, moment ) );
