( function( app, common, core ) {

    common.image = {};

    /**
     * Will return the appropriate variant from a photo, given a tag
     * @param {Array<Variant>} variants - array of variants
     * @param {String} tagLabel - the variant tag label to match against
     * @return {Variant} variant
     */
    common.image.getVariantByTag = function( variants, tagLabel ) {
        for ( var i = 0; i < variants.length; i++ ) {
            if ( variants[ i ].tag.label === tagLabel ) {
                return variants[ i ];
            }
        }
        return null;
    };

    /**
     * Retrieves the ODIR-friendly variant URL
     * @param  {PhotoResponse} image  - the image to retrieve the URL from, as it comes from the API (requires "onDemandUrl" to exist under the root)
     * @param  {Number}        height - optional, the desired height
     * @param  {Number}        width  - optional, the desired height
     * @return {String}               - the variant URL
     */
    common.image.getOnDemandImageUrl = function getOnDemandImageUrl( image, height, width ) {
        if ( image && image.onDemandUrl ) {
            let properties = [];
            if ( height ) {
                properties.push( `height=${height}` );
            }
            if ( width ) {
                properties.push( `width=${width}` );
            }

            if ( properties.length > 0 ) {
                return `${image.onDemandUrl}?${properties.join( '&' )}`;
            }

            return `${image.onDemandUrl}?width=500`;

        }
    };

    /**
	 * using an array of variants return a variant object with the closest matching width
	 * @param {Array} variants an array of variant objects ( from cms api )
	 * @param {int} width desired width
	 * @returns {object} variant object that most closely matches the desired width
	 */
    common.image.getVariantWithWidth = function( variants = [], width = 0 ) {
        let diff = Infinity;
        let nearest = false;

        variants.forEach( ( variant, i ) => {
            const candidateDiff = Math.abs( variant.width - width );
            if ( candidateDiff < diff ) {
                diff = candidateDiff;
                nearest = i;
            }
        } );

        return variants[ nearest ];
    };

    /**
     * Get Player Image
     *
     * Returns URL of player image.
     *
     * @param { String } playerId - the player's opta ID.
     * @param { String } size - size of image
     * @param { String } ext - the extension of the image (e.g. png, jpg, etc..)
     *
     * @returns { string } the api string
     */
    common.image.getPlayerImage = ( playerId, size = '240x400', ext = 'png' ) => {
        if ( !playerId ) {
            return null;
        }

        return `${ app.environment.playerImagePath }/${ size }/public/player_full_${ playerId }_0.${ ext }`;
    };

    /**
     * Get Tournament Image or set Fallback Class
     *
     * Fires off a request to the content api to get a relevant image, if nothing
     * is found it will add a class to render a default background image.
     *
     * @param { Object } tournament - the tournament object
     * @param { Array } mediaQueryConfig - the thumbnail image config
     */
    common.image.getTournamentImage = ( tournament, mediaQueryConfig ) => {

        const url = common.createContentPath( 'PHOTO', {
            pageSize: 1,
            references: `TENNIS_TOURNAMENTGROUP:${ tournament.tournamentGroup.id }`
        } );

        const headers = [ { label: 'account', value: app.account } ];
        core.data.request( url, 'GET', ( response ) => _renderImage( response, tournament, mediaQueryConfig ), this, null, null, headers );
    };

    /**
     * Render Image
     * Callback function for the getTournamentImage request. Parses the response
     * from the content API and renders a lazyimage into the marker div, or adds
     * a class for a default image to display.
     * @param {Object} response - Response from the content API
     * @param {Object} tournament - Tournament instance to garner surface
     * @param {Array} mediaQueryConfig - Image config for the lazy-image template
     */
    const _renderImage = ( response, tournament, mediaQueryConfig ) => {
        const wrapper = document.querySelector( `.js-tournament-image-${ tournament.tournamentGroup.id}` );

        if ( !response || !response.content || response.content.length === 0 ) {
            if ( wrapper ) {
                core.style.addClass( wrapper, 'tournament-thumbnail__default-image' );
                if ( tournament.surface ) {
                    core.style.addClass( wrapper, `tournament-thumbnail__default-image--${ tournament.surface.toLowerCase() }` );
                }
            }
            return;
        }

        const data = {
            mediaQueryConfig: app.common.content.getContentModel( response.content[ 0 ], mediaQueryConfig ).mediaQueryConfig,
            objectFit: true
        };
        const imageHTML = app.templating.render( { data }, 'common.lazy-image', true );

        if ( wrapper ) {
            wrapper.appendChild( imageHTML );
            new app.LazyLoad( wrapper );
        }
    };

}( PULSE.app, PULSE.app.common, PULSE.core ) );
