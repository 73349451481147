/* eslint-disable no-magic-numbers */
( function( app ) {
    'use strict';

    /**
     * Progress
     *
     * Creates a progress indicator to track how much of an element a user has viewed
     *
     * @param { HTMLElement } container - the container this widget was initialised on. Usually the `body` element.
     */
    app.Progress = function Progress( container ) {
        const _window = window;
        const _self = this;

        _self.container = container;
        _self.progressBar = _self.container.querySelector( '.js-progress-bar' );
        _self.trackProgress = document.querySelector( '.js-track-progress' );

        _window.addEventListener( 'scroll', () => {
            const top = document.documentElement.scrollTop;
            const progress = _self.trackProgress.scrollHeight;
            const width = Math.ceil( ( top / ( progress - window.innerHeight ) ) * 100 ) / 100;
            
            _self.progressBar.style.transform = `scaleX(${width})`;
        } );
    };

    app.widgetInitialiser.addMultipleWidgetsByName( 'progress', app.Progress );
} ( PULSE.app ) );
