( function( app ) {
    'use strict';

    const GLUE_CLASS = 'is-glued';
    const GLUED_CLASS = 'js-was-glued';
    const OBSERVABLE_SELECTOR = '.js-will-glue';
    const PLACEHOLDER_CLASS = 'js-glue-placeholder';
    const MIN_THRESHOLD = 0.025;

    /**
     * Viewport Glue
     *
     * Will glue items matched by `OBSERVABLE_SELECTOR` to the top of the window
     * viewport on scroll. When multiple items are matched, they will become
     * stacked at the top of the viewport on scroll.
     *
     * This widget is used to achieve 'sticky' nav and element behaviour.
     *
     *
     * @param { HTMLElement } container - the container this widget was initialised on. Usually the `body` element.
     */
    app.ViewportGlue = function ViewportGlue( container ) {
        const _self = this;
        _self.container = container;
        _self.items = [].slice.call( container.querySelectorAll( OBSERVABLE_SELECTOR ) );
        _self.heights = [];
        _self.observer = makeObserver.call( _self, _self.items );
    };

    /**
     * Make Observer
     *
     * Will make an intersection observer based on the passed arguments.
     *
     * @param { Array } targets - array of target elements.
     * @param { String } margin - the `rootMargin` the IntersecitonObserver should use.
     *
     * @returns { Object } IntersectionObserver instance.
     */
    const makeObserver = function makeObserver( targets, margin = '0px' ) {
        const _self = this;
        const options = {
            root: null,
            rootMargin: margin,
            threshold: [ MIN_THRESHOLD, 1 ]
        };

        const observer = new IntersectionObserver( handler.bind( _self ), options );
        targets.forEach( target => {
            observer.observe( target );
        } );

        return observer;
    };

    /**
     * Handler
     *
     * Callback function for IntersectionObserver.
     *
     * @param { Array } items - array of observed items that have changed.
     * @param { Object } observer - intersection observer instance.
     */
    const handler = function handler( items, observer ) {
        const _self = this;

        // Group items into items that are 'exiting' the viewport and items that
        // are 'entering' the viewport.
        const group = items.reduce( ( collection, item ) => {
            if ( item.boundingClientRect.top <= _self.heights.reduce( sum, 0 ) &&
            ! item.target.classList.contains( GLUE_CLASS ) &&
            ! item.target.classList.contains( PLACEHOLDER_CLASS ) ) {
                collection.exiting.push( item );
            }

            if ( item.boundingClientRect.top > 0 &&
            item.target.classList.contains( PLACEHOLDER_CLASS ) &&
            item.isIntersecting ) {
                collection.entering.push( item );
            }

            return collection;
        }, { exiting: [], entering: [] } );

        if ( ! group.exiting.length && ! group.entering.length ) {
            return;
        }

        // We're going to have to remake the observer so we're disconnecting this one
        observer.disconnect();

        // Glue exiting items
        group.exiting.forEach( item => {
            glue.call( _self, item.target, _self.heights.reduce( sum, 0 ) );
            _self.heights.push( item.boundingClientRect.height );
        } );

        // Unglue entering items
        group.entering.forEach( item => {
            unglue( item.target );
            _self.heights.splice( _self.heights.indexOf( item.boundingClientRect.height ), 1 );
        } );

        /**
         * Gather total height of glued items and make a new observer with an
         * adjusted rootMargin so we get the correct trigger point.
         */
        const tot = _self.heights.reduce( sum, 0 );
        _self.observer = makeObserver.call( _self, _self.items, `-${ tot }px 0px 0px 0px` );
    };

    /**
     * Glue
     *
     * Glue an item to the viewport.
     *
     * @param { HTMLElement } item - the element being glued.
     * @param { Number } position - the `top` position of the glued item relative to the viewport.
     */
    const glue = function glue( item, position = 0 ) {
        const _self = this;

        if ( getComputedStyle( item, null ).display === 'none' ) {
            return;
        }

        const placeholder = document.createElement( 'div' );
        placeholder.classList.add( PLACEHOLDER_CLASS );
        placeholder.setAttribute( 'style', `clear:both; height: ${ item.clientHeight }px; width: 100%;` );

        item.classList.add( GLUE_CLASS, GLUED_CLASS );
        item.setAttribute( 'style', `top: ${ position }px;` );

        const node = item.insertAdjacentElement( 'beforebegin', placeholder );
        _self.items.push( node );
    };

    /**
     * Unglue
     *
     * Unglue an item from the viewport.
     *
     * @param { HTMLElement } item - the item being unglued.
     */
    const unglue = ( item ) => {
        let original = item.nextElementSibling;
        original.classList.remove( GLUE_CLASS, GLUED_CLASS );
        item.remove();
    };

    /**
     * Sum
     *
     * Should be used in a `reduce` function. Will sum an array of numbers.
     *
     * @param { Number } acc - the accumulator
     * @param { Number } cur - the current number
     *
     * @returns { Number } - summed number.
     */
    const sum = ( acc, cur ) => acc + cur;

    app.widgetInitialiser.addMultipleWidgetsByName( 'viewport-glue', app.ViewportGlue );
} ( PULSE.app ) );
