if ( !PULSE ) { var PULSE = {}; }
if ( !PULSE.CLIENT ) { PULSE.CLIENT = {}; }
if ( !PULSE.CLIENT.Tracking ) { PULSE.CLIENT.Tracking = {}; }

PULSE.CLIENT.Tracking.event = function( category, detail ) {

    var trackingEvent = {
        event: category,
        detail: detail
    };

    if ( typeof window.dataLayer !== 'undefined' ) {
        dataLayer.push( trackingEvent );
    } else {
        console.warn( 'Unable to send to GTM:', trackingEvent );
    }
};