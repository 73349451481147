( function( app, core ) {

    'use strict';

    /**
     * @constructor Parallax
     * @param {HTMLElement} container the element the widget manipulates/populates/affects
     */
    app.Parallax = function( container ) {
        var _self = this;
        _self.container = container;

        const images = container.querySelectorAll( '.js-parallax' );
        images.forEach( image => {
            core.style.addClass( image, 'parallax' );

            image.addEventListener( 'mousemove', function( element ) {
                const rect = this.getBoundingClientRect();
                this.style.setProperty( '--x', element.clientX - ( rect.left + Math.floor( rect.width / 2 ) ) ); // eslint-disable-line no-magic-numbers
                this.style.setProperty( '--y', element.clientY - ( rect.top + Math.floor( rect.height / 2 ) ) ); // eslint-disable-line no-magic-numbers
            } );
    
            image.addEventListener( 'mouseleave', function() {
                this.style.setProperty( '--x', 0 );
                this.style.setProperty( '--y', 0 );
            } );
        } );
        const contentLayers = container.querySelectorAll( '.js-parallax-content' );
        contentLayers.forEach( contentLayer => {
            core.style.addClass( contentLayer, 'parallax__content' );
        } );
    };

    app.widgetInitialiser.addMultipleWidgetsByName( 'parallax', app.Parallax );

}( PULSE.app, PULSE.core ) );
